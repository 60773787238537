<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h1 class='display-2'>Bank Aktivitas Struktural</h1>
          </template>
          <crud
            :crudSettings="crudSettings"
            :headers="headers"
            :responseData="{data:items}"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            @onTableChange="updateTable"
          >
          <template v-slot:append-header>
            <v-btn color="primary" class="mr-2" dark><v-icon>mdi-file-excel</v-icon> Export Aktivitas</v-btn>
            <v-btn color="primary" dark><v-icon>mdi-file-excel</v-icon> Export Aktivitas + Kamus</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark><v-icon>mdi-plus</v-icon> Tambah Aktivitas</v-btn>
          </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Nama Aktivitas', value:'nama_aktivitas', sortable:false, class:'header-index-profile'},
        {text:'Satuan Output', value:'output', sortable:false, class:'header-index-profile'},
        {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
        {text:'Tingkat Kesulitan', value:'kesulitan', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:false,
      },
    }
  },

  watch:{},

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{},

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){},
  },
}
</script>

<style lang="css" scoped>
</style>
